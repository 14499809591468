import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import Toast from "react-bootstrap/Toast";
import "./Header.css";

const Header = () => {
  const [showToast, setShowToast] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowToast(true);
    }, 30000); // Show toast after 30 seconds

    const toastDuration = setTimeout(() => {
      setShowToast(false);
    }, 35000); // Hide toast 5 seconds after appearing

    return () => {
      clearTimeout(timer);
      clearTimeout(toastDuration);
    };
  }, []);

  return (
    <>
      <Navbar bg="dark" variant="dark" expand="md" className="header-container">
        <Container>
          {/* Left side with logo */}
          <Navbar.Brand as={Link} to="/" className="header-logo">
            <img
              src="/images/FootballMemeLogo.png"
              alt="Football Meme Logo"
              className="logo-img"
            />
          </Navbar.Brand>

          {/* Center with FootballMeme text styled as the logo */}
          <Navbar.Brand as={Link} to="/" className="logo-text">
            FootballMeme
          </Navbar.Brand>

          {/* Hamburger toggler for mobile */}
          <Navbar.Toggle aria-controls="navbar-nav" />

          {/* Right side with navigation links */}
          <Navbar.Collapse id="navbar-nav">
            <Nav className="ms-auto header-nav">
              <Nav.Link as={Link} to="/donate" className="donate-button">
                Donate
              </Nav.Link>
              <Nav.Link as={Link} to="/">
                Home
              </Nav.Link>
              <Nav.Link as={Link} to="/about">
                About
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      {/* Toast */}
      <Toast
        show={showToast}
        onClose={() => setShowToast(false)}
        className="donate-toast"
      >
        <Toast.Body>
          Help defray the costs of running Football Meme. Click{" "}
          <strong>Donate</strong>.
        </Toast.Body>
      </Toast>
    </>
  );
};

export default Header;
