import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import Alert from "react-bootstrap/Alert";
import "./Donate.css";

// Load Stripe using the publishable key from the environment variable
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const DonationForm = () => {
  const [amount, setAmount] = useState("");
  const [customAmount, setCustomAmount] = useState("");
  const [fullName, setFullName] = useState(""); // State for full name
  const [isProcessing, setIsProcessing] = useState(false);
  const [alert, setAlert] = useState({ visible: false, type: "", message: "" });

  const stripe = useStripe();
  const elements = useElements();

  const donationAmounts = [3, 7, 17, 28, 44, 72, 81];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsProcessing(true);

    const selectedAmount = customAmount ? customAmount : amount;
    if (!selectedAmount) {
      setAlert({
        visible: true,
        type: "danger",
        message: "Please select or enter an amount to donate.",
      });
      setIsProcessing(false);
      return;
    }

    if (!fullName.trim()) {
      setAlert({
        visible: true,
        type: "danger",
        message: "Please enter your full name.",
      });
      setIsProcessing(false);
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/create-payment-intent`,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ amount: selectedAmount }),
        }
      );

      const { clientSecret } = await response.json();

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        console.error("CardElement is null");
        return;
      }

      const { paymentIntent, error } = await stripe.confirmCardPayment(
        clientSecret,
        {
          payment_method: {
            card: cardElement,
            billing_details: {
              name: fullName, // Use full name entered by the user
            },
          },
        }
      );

      if (error) {
        console.error("Payment failed:", error);
        setAlert({
          visible: true,
          type: "danger",
          message: "Payment failed. Please try again.",
        });
      } else if (paymentIntent.status === "succeeded") {
        setAlert({
          visible: true,
          type: "success",
          message: "Thank you for your donation!",
        });
      }
    } catch (error) {
      console.error("Error processing payment:", error);
      setAlert({
        visible: true,
        type: "danger",
        message: "An error occurred. Please try again.",
      });
    }

    setIsProcessing(false);
  };

  return (
    <>
      {alert.visible && (
        <Alert
          variant={alert.type}
          onClose={() => setAlert({ visible: false, type: "", message: "" })}
          dismissible
        >
          {alert.message}
        </Alert>
      )}

      <form onSubmit={handleSubmit} className="donation-form">
        <h2>Select a Donation Amount</h2>
        <div className="amount-buttons">
          {donationAmounts.map((amt) => (
            <button
              type="button"
              key={amt}
              className={`amount-button ${amt === amount ? "selected" : ""}`}
              onClick={() => {
                setAmount(amt);
                setCustomAmount("");
              }}
            >
              ${amt}
            </button>
          ))}
        </div>
        <div className="custom-amount">
          <label>Enter a custom amount:</label>
          <input
            type="number"
            placeholder="Custom Amount"
            value={customAmount}
            onChange={(e) => {
              setCustomAmount(e.target.value);
              setAmount("");
            }}
          />
        </div>
        <div className="full-name">
          <label htmlFor="fullName">Full Name:</label>
          <input
            type="text"
            id="fullName"
            placeholder="Joe Gibbs"
            value={fullName}
            onChange={(e) => setFullName(e.target.value)}
            required
          />
        </div>
        <h2>Payment Details</h2>
        <CardElement className="card-element" />
        <button
          type="submit"
          className="donate-submit"
          disabled={isProcessing || !stripe}
        >
          {isProcessing ? "Processing..." : "Donate"}
        </button>
      </form>
    </>
  );
};

const Donate = () => {
  return (
    <div className="donate-container">
      <Helmet>
        <title>Donate | Football Meme</title>
        <meta
          name="description"
          content="Support Football Meme by donating to help us cover server costs and maintain this platform."
        />
      </Helmet>

      <div className="donate-page">
        <h1 className="donate-header">Support FootballMeme</h1>
        <p>
          It costs good money to operate FootballMeme. Server costs, Twitter API
          costs, and OpenAI costs add up quickly.
        </p>
        <p>
          This is a passion project for me and anything you can do to help me
          keep it up and running would be immensely appreciated!
        </p>
        <Elements stripe={stripePromise}>
          <DonationForm />
        </Elements>
      </div>
    </div>
  );
};

export default Donate;
