import React, { useState, useEffect } from "react";
import axios from "axios";
import PlayerMovement from "./PlayerMovement";
import SearchBar from "./SearchBar";
import { Container, ListGroup, Alert } from "react-bootstrap";
import "./PlayerMovementList.css";

const PlayerMovementList = () => {
  const [searchText, setSearchText] = useState("");
  const [transactions, setTransactions] = useState([]);
  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/transactions?limit=100`
        );
        const fetchedTransactions = response.data.transactions.slice(0, 50);
        setTransactions(fetchedTransactions);
        setFilteredResults(fetchedTransactions);
      } catch (error) {
        console.error("Error fetching transactions:", error);
        setError("Failed to load transactions.");
      }
    };

    const fetchPlayersAndTeams = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/search`
        );
        setPlayers(response.data.players || []);
        setTeams(response.data.teams || []);
      } catch (error) {
        console.error("Error fetching search data:", error);
      }
    };

    fetchTransactions();
    fetchPlayersAndTeams();
  }, []);

  useEffect(() => {
    if (searchText.trim()) {
      const filteredTransactions = transactions.filter((transaction) => {
        const playerName = transaction.player_name
          ? transaction.player_name.toLowerCase()
          : "";
        const teamName = transaction.team_name
          ? transaction.team_name.toLowerCase()
          : "";
        const oldTeamName = transaction.old_team_name
          ? transaction.old_team_name.toLowerCase()
          : "";

        return (
          playerName.includes(searchText.toLowerCase()) ||
          teamName.includes(searchText.toLowerCase()) ||
          oldTeamName.includes(searchText.toLowerCase())
        );
      });
      setFilteredResults(filteredTransactions);
    } else {
      setFilteredResults(transactions);
    }
  }, [searchText, transactions]);

  return (
    <Container className="player-movements-container">
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        filteredPlayers={players}
        filteredTeams={teams}
      />
      {error && <Alert variant="danger">{error}</Alert>}
      {filteredResults.length > 0 ? (
        <ListGroup variant="flush" className="player-movements-list">
          {filteredResults.map((transaction, index) => (
            <PlayerMovement key={index} transaction={transaction} />
          ))}
        </ListGroup>
      ) : (
        <Alert variant="info">No transactions found</Alert>
      )}
    </Container>
  );
};

export default PlayerMovementList;
