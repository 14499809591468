import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import Header from "./components/Header";
import MainPage from "./components/MainPage";
import About from "./components/About";
import SocialPage from "./components/SocialPage";
import Compare from "./components/Compare";
import Donate from "./components/Donate";
import Footer from "./components/Footer";
import Container from "react-bootstrap/Container";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css"; // Import Bootstrap CSS

const App = () => {
  return (
    <HelmetProvider>
      <Router>
        <div className="app">
          <Header />
          <Container as="main" className="content">
            <Routes>
              <Route path="/" element={<MainPage />} />
              <Route path="/about" element={<About />} />
              <Route path="/social" element={<SocialPage />} />
              <Route path="/compare" element={<Compare />} />
              <Route path="/donate" element={<Donate />} />
            </Routes>
          </Container>
          <Footer />
        </div>
      </Router>
    </HelmetProvider>
  );
};

export default App;
