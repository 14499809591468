import React, { useState, useEffect } from "react";
import axios from "axios";
import Badge from "react-bootstrap/Badge";
import { timeDifference } from "../utils/timeUtils";
import "./Article.css";

const getPillVariant = (articleType) => {
  switch (articleType) {
    case "news":
      return "success";
    case "rumor":
      return "warning";
    case "transaction":
      return "danger";
    case "game":
      return "primary";
    case "injury":
      return "warning";
    default:
      return "secondary";
  }
};

const Article = ({ article }) => {
  const [twitterPosts, setTwitterPosts] = useState([]);
  const [threadsPosts, setThreadsPosts] = useState([]);
  const [blueskyPosts, setBlueSkyPosts] = useState([]);

  useEffect(() => {
    const fetchRelatedPosts = async () => {
      try {
        // Fetch Twitter related posts
        const twitterResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/getrelatedposts`,
          { params: { article_url: article.url, social_network: "Twitter" } }
        );
        setTwitterPosts(twitterResponse.data.relatedPosts || []);

        // Fetch Threads related posts
        const threadsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/getrelatedposts`,
          { params: { article_url: article.url, social_network: "Threads" } }
        );
        setThreadsPosts(threadsResponse.data.relatedPosts || []);

        // Fetch BlueSky related posts
        const blueskyResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/getrelatedposts`,
          { params: { article_url: article.url, social_network: "BlueSky" } }
        );
        setBlueSkyPosts(blueskyResponse.data.relatedPosts || []);
      } catch (error) {
        console.error("Error fetching related posts:", error);
      }
    };

    fetchRelatedPosts();
  }, [article.url]);

  const handleClick = async () => {
    try {
      await axios.post(`${process.env.REACT_APP_API_URL}/registerclick`, {
        url: article.url, // Send the article URL in the request body
      });
      console.log("Click registered successfully");
    } catch (error) {
      console.error("Error registering click:", error);
    }
  };

  return (
    <div className="article-item d-flex flex-column my-3">
      {/* Article headline */}
      <div className="d-flex align-items-center">
        <a
          href={article.url}
          target="_blank"
          rel="noopener noreferrer"
          className="article-headline"
          onClick={handleClick} // Register click when the link is clicked
        >
          {article.headline}
        </a>
        <Badge bg={getPillVariant(article.type)} className="ms-2">
          {article.type}
        </Badge>
      </div>

      {/* Time and domain name */}
      <small className="text-muted">
        {timeDifference(article.publication_date)} |{" "}
        <a
          href={article.url}
          target="_blank"
          rel="noopener noreferrer"
          className="article-domain"
        >
          {article.site}
        </a>
      </small>

      {/* Related Posts */}
      {twitterPosts.length > 0 && (
        <div className="related-posts mt-3">
          <span className="social-network-label">
            <img
              src="/images/twitter_logo.png"
              alt="Twitter"
              className="social-logo"
            />{" "}
          </span>
          {twitterPosts.map((post, index) => (
            <span key={index} className="related-post-link">
              <a
                href={post.post_url}
                target="_blank"
                rel="noopener noreferrer"
                title={post.post_text}
              >
                @{post.post_author}
              </a>
              {index < twitterPosts.length - 1 && ", "}
            </span>
          ))}
        </div>
      )}
      {threadsPosts.length > 0 && (
        <div className="related-posts mt-3">
          <span className="social-network-label">
            <img
              src="/images/threads_logo.png"
              alt="Threads"
              className="social-logo"
            />{" "}
          </span>
          {threadsPosts.map((post, index) => (
            <span key={index} className="related-post-link">
              <a
                href={post.post_url}
                target="_blank"
                rel="noopener noreferrer"
                title={post.post_text}
              >
                @{post.post_author}
              </a>
              {index < threadsPosts.length - 1 && ", "}
            </span>
          ))}
        </div>
      )}
      {blueskyPosts.length > 0 && (
        <div className="related-posts mt-3">
          <span className="social-network-label">
            <img
              src="/images/bluesky_logo.png"
              alt="BlueSky"
              className="social-logo"
            />{" "}
          </span>
          {blueskyPosts.map((post, index) => (
            <span key={index} className="related-post-link">
              <a
                href={post.post_url}
                target="_blank"
                rel="noopener noreferrer"
                title={post.post_text}
              >
                @{post.post_author}
              </a>
              {index < blueskyPosts.length - 1 && ", "}
            </span>
          ))}
        </div>
      )}
    </div>
  );
};

export default Article;
