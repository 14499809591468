import React from "react";
import Card from "react-bootstrap/Card";
import { timeDifference } from "../utils/timeUtils";
import "./Video.css";

const Video = ({ video }) => {
  const {
    thumbnail_image: thumbnailUrl,
    title: videoTitle,
    video_url: videoUrl,
    publication_date: publishedAt,
  } = video;

  return (
    <Card className="video-item shadow-sm">
      <a href={videoUrl} target="_blank" rel="noopener noreferrer">
        <Card.Img
          variant="top"
          src={thumbnailUrl}
          alt={videoTitle}
          className="video-thumbnail"
        />
      </a>
      <Card.Body>
        <Card.Title as="h6" className="video-title">
          {videoTitle}
        </Card.Title>
        <Card.Text className="video-timestamp text-muted">
          {timeDifference(publishedAt)}
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default Video;
