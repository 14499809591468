import React, { useState, useEffect } from "react";
import axios from "axios";
import Article from "./Article";
import SearchBar from "./SearchBar";
import { Container, Button, Spinner, Alert } from "react-bootstrap";
import "./ArticleList.css";

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [searchText, setSearchText] = useState("");
  const [players, setPlayers] = useState([]);
  const [teams, setTeams] = useState([]);
  const [filteredResults, setFilteredResults] = useState([]);
  const articlesPerPage = 30;

  useEffect(() => {
    const fetchArticles = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_API_URL
          }/articles?limit=${articlesPerPage}&offset=${
            (page - 1) * articlesPerPage
          }`
        );
        setArticles(response.data.articles || []);
        setFilteredResults(response.data.articles || []);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching articles:", error);
        setError("Failed to load articles");
        setLoading(false);
      }
    };

    fetchArticles();
  }, [page]);

  useEffect(() => {
    const fetchPlayersAndTeams = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/search`
        );
        setPlayers(response.data.players || []);
        setTeams(response.data.teams || []);
      } catch (error) {
        console.error("Error fetching search data:", error);
      }
    };

    fetchPlayersAndTeams();
  }, []);

  // Handle search text input and filter articles
  useEffect(() => {
    if (searchText.trim()) {
      console.log("Filtering with search text:", searchText); // Debugging log

      const searchLower = searchText.toLowerCase();
      const filteredArticles = articles.filter((article) => {
        const articlePlayers = Array.isArray(article.player_names)
          ? article.player_names
              .filter((player) => player) // Filter out falsy values
              .map((player) => player.toLowerCase())
          : [];
        const articleTeams = Array.isArray(article.team_names)
          ? article.team_names
              .filter((team) => team) // Filter out falsy values
              .map((team) => team.toLowerCase())
          : [];
        const articleHeadline = article.headline
          ? article.headline.toLowerCase()
          : "";

        const isPlayerMatch = articlePlayers.some((player) =>
          player.includes(searchLower)
        );
        const isTeamMatch = articleTeams.some((team) =>
          team.includes(searchLower)
        );
        const isHeadlineMatch = articleHeadline.includes(searchLower);

        console.log(
          `Article: ${article.headline}, Players: ${articlePlayers}, Teams: ${articleTeams}, Matches:`,
          { isPlayerMatch, isTeamMatch, isHeadlineMatch }
        ); // Debugging log

        return isPlayerMatch || isTeamMatch || isHeadlineMatch;
      });

      console.log("Filtered articles:", filteredArticles); // Debugging log
      setFilteredResults(filteredArticles);
    } else {
      console.log("No search text, displaying all articles"); // Debugging log
      setFilteredResults(articles); // If no search, display all articles
    }
  }, [searchText, articles]);
  
  if (loading) return <Spinner animation="border" variant="primary" />;

  if (error) return <Alert variant="danger">{error}</Alert>;

  return (
    <Container className="article-list">
      <SearchBar
        searchText={searchText}
        setSearchText={setSearchText}
        filteredPlayers={players}
        filteredTeams={teams}
      />
      {filteredResults.length > 0 ? (
        filteredResults.map((article) => (
          <Article key={article.url} article={article} />
        ))
      ) : (
        <Alert variant="info">No articles found</Alert>
      )}
      <div className="pagination-container mt-4">
        <Button
          onClick={() => setPage((prevPage) => prevPage - 1)}
          disabled={page === 1}
        >
          Previous
        </Button>
        <Button
          onClick={() => setPage((prevPage) => prevPage + 1)}
          disabled={filteredResults.length < articlesPerPage}
          className="ms-2"
        >
          Next
        </Button>
      </div>
    </Container>
  );
};

export default ArticleList;
