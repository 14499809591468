import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import TrendingTopics from "./TrendingTopics";
import ArticleList from "./ArticleList";
import VideoList from "./VideoList";
import PlayerMovementList from "./PlayerMovementList";
import "./MainPage.css";
import { Container, Row, Col } from "react-bootstrap";

const MainPage = ({ articles, videos }) => {
  const [activePane, setActivePane] = useState("left");

  const handlePaneChange = (event) => {
    setActivePane(event.target.value);
  };

  return (
    <Container fluid className="main-page-container">
      <Helmet>
        <title>Home | Football Meme | The Fast Index on All Things NFL</title>
        <meta
          name="description"
          content="The super-fast way to see what's happening in the NFL."
        />
      </Helmet>

      {/* Dropdown for mobile view */}
      <div className="pane-dropdown d-md-none">
        <select
          onChange={handlePaneChange}
          value={activePane}
          className="form-select"
        >
          <option value="left">Latest NFL News</option>
          <option value="middle">Curated NFL Videos</option>
          <option value="right">Player Movements</option>
        </select>
      </div>

      {/* Single TrendingTopics component for both players and teams */}
      <Row className="my-4">
        <Col>
          <TrendingTopics />
        </Col>
      </Row>

      {/* Render for desktop view */}
      <Row className="main-page d-none d-md-flex">
        <Col md={4}>
          <h2 className="section-header">Latest NFL News</h2>
          <ArticleList articles={articles} />
        </Col>

        <Col md={4}>
          <h2 className="section-header">Curated NFL Videos</h2>
          <VideoList videos={videos} />
        </Col>

        <Col md={4}>
          <h2 className="section-header">Player Movements</h2>
          <PlayerMovementList />
        </Col>
      </Row>

      {/* Render for mobile view based on activePane */}
      <Row className="d-md-none">
        {activePane === "left" && (
          <Col>
            <h2 className="section-header">Latest NFL News</h2>
            <ArticleList articles={articles} />
          </Col>
        )}
        {activePane === "middle" && (
          <Col>
            <h2 className="section-header">Curated NFL Videos</h2>
            <VideoList videos={videos} />
          </Col>
        )}
        {activePane === "right" && (
          <Col>
            <h2 className="section-header">Player Movements</h2>
            <PlayerMovementList />
          </Col>
        )}
      </Row>
    </Container>
  );
};

export default MainPage;
