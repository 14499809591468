import React, { useState, useEffect } from "react";
import Video from "./Video";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Pagination from "react-bootstrap/Pagination";
import "./VideoList.css";

const VideoList = () => {
  const [videos, setVideos] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const videosPerPage = 33;

  useEffect(() => {
    const fetchVideos = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `${
            process.env.REACT_APP_API_URL
          }/videos?limit=${videosPerPage}&offset=${(page - 1) * videosPerPage}`
        );
        const data = await response.json();
        setVideos(data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching videos:", error);
        setError("Failed to load videos");
        setLoading(false);
      }
    };

    fetchVideos();
  }, [page]);

  const handleNextPage = () => setPage((prevPage) => prevPage + 1);
  const handlePrevPage = () => page > 1 && setPage((prevPage) => prevPage - 1);

  if (loading) return <p>Loading videos...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Container fluid>
      <Row className="gx-0 gy-0">
        {videos.length > 0 ? (
          videos.map((video) => (
            <Col key={video.video_url} sm={12} md={6} lg={4}>
              <Video video={video} />
            </Col>
          ))
        ) : (
          <p>No videos found</p>
        )}
      </Row>
      <div className="d-flex justify-content-center mt-3">
        <Pagination>
          <Pagination.Prev onClick={handlePrevPage} disabled={page === 1} />
          <Pagination.Next
            onClick={handleNextPage}
            disabled={videos.length < videosPerPage}
          />
        </Pagination>
      </div>
    </Container>
  );
};

export default VideoList;
