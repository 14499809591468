import React, { useState, useEffect } from "react";
import axios from "axios";
import "./SocialPage.css";

const SocialPage = () => {
  const [formData, setFormData] = useState({
    postUrl: "",
    postText: "",
    postAuthor: "",
    socialNetwork: "",
    postDate: "",
    postId: "",
  });
  const [alert, setAlert] = useState("");
  const [socialPosts, setSocialPosts] = useState([]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Always update the form data for non-URL fields
    if (name !== "postUrl") {
      setFormData((prev) => ({ ...prev, [name]: value }));
      return;
    }

    // For `postUrl`, validate and parse
    setFormData((prev) => ({ ...prev, postUrl: value }));

    try {
      const url = new URL(value);

      // Determine network
      if (
        url.hostname.includes("x.com") ||
        url.hostname.includes("twitter.com")
      ) {
        setFormData((prev) => ({ ...prev, socialNetwork: "Twitter" }));
      } else if (url.hostname.includes("threads.net")) {
        setFormData((prev) => ({ ...prev, socialNetwork: "Threads" }));
      } else {
        setFormData((prev) => ({ ...prev, socialNetwork: "" }));
      }

      // Extract post ID and author
      const pathParts = url.pathname.split("/");
      if (pathParts.includes("status") || pathParts.includes("p")) {
        const postId = pathParts.pop();
        const postAuthor = pathParts[1] || "";
        setFormData((prev) => ({ ...prev, postId, postAuthor }));
      }
    } catch (error) {
      console.error("Invalid URL:", error);
    }
  };

  const clearFields = () => {
    setFormData({
      postUrl: "",
      postText: "",
      postAuthor: "",
      socialNetwork: "",
      postDate: "",
      postId: "",
    });
    setAlert("");
  };

  const addSocialPost = async () => {
    console.log("Submitting Form Data:", formData); // Debugging
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/addsocialpost`,
        formData
      );
      setAlert("Successfully added social media post");
      clearFields();
      fetchSocialPosts();
    } catch (error) {
      console.error("Error adding social media post:", error);
      setAlert("Error adding social media post");
    }
  };

  const fetchSocialPosts = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/getsocialposts`
      );
      setSocialPosts(response.data || []);
    } catch (error) {
      console.error("Error fetching social posts:", error);
    }
  };

  useEffect(() => {
    fetchSocialPosts();
  }, []);

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Social Media Post Manager</h1>
      <div className="card p-4 mb-4">
        <div className="mb-3">
          <label htmlFor="postUrl" className="form-label">
            Post URL:
          </label>
          <input
            type="text"
            id="postUrl"
            name="postUrl"
            className="form-control"
            value={formData.postUrl}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="postText" className="form-label">
            Post Text:
          </label>
          <textarea
            id="postText"
            name="postText"
            className="form-control"
            rows="3"
            value={formData.postText}
            onChange={handleChange}
          ></textarea>
        </div>
        <div className="mb-3">
          <label htmlFor="postAuthor" className="form-label">
            Post Author:
          </label>
          <input
            type="text"
            id="postAuthor"
            name="postAuthor"
            className="form-control"
            value={formData.postAuthor}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="socialNetwork" className="form-label">
            Social Network:
          </label>
          <input
            type="text"
            id="socialNetwork"
            name="socialNetwork"
            className="form-control"
            value={formData.socialNetwork}
            readOnly
          />
        </div>
        <div className="mb-3">
          <label htmlFor="postDate" className="form-label">
            Post Date:
          </label>
          <input
            type="datetime-local"
            id="postDate"
            name="postDate"
            className="form-control"
            value={formData.postDate}
            onChange={handleChange}
          />
        </div>
        <div className="mb-3">
          <label htmlFor="postId" className="form-label">
            Post ID:
          </label>
          <input
            type="text"
            id="postId"
            name="postId"
            className="form-control"
            value={formData.postId}
            readOnly
          />
        </div>
        <div className="d-flex gap-2">
          <button className="btn btn-primary" onClick={addSocialPost}>
            Add Social Post
          </button>
          <button className="btn btn-secondary" onClick={clearFields}>
            Clear Fields
          </button>
        </div>
      </div>
      {alert && <div className="alert alert-info">{alert}</div>}
      <div className="card p-4">
        <h2 className="mb-4">Recent Social Posts</h2>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Network</th>
              <th>Post ID</th>
              <th>Author</th>
              <th>Post Text</th>
              <th>Link</th>
            </tr>
          </thead>
          <tbody>
            {socialPosts.length > 0 ? (
              socialPosts.map((post) => (
                <tr key={post.id}>
                  <td>{post.network || "N/A"}</td>
                  <td>{post.post_id || "N/A"}</td>
                  <td>{post.post_author || "N/A"}</td>
                  <td>{post.post_text || "N/A"}</td>
                  <td>
                    <a
                      href={post.post_url || "#"}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {post.network ? `View on ${post.network}` : "View Post"}
                    </a>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No social posts available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SocialPage;
