// components/Footer.js
import React from "react";
import Container from "react-bootstrap/Container";
import "./Footer.css"; // Custom footer styles

const Footer = () => {
  return (
    <footer className="footer-container">
      <Container>
        <p>&copy; 2024 Strategic Nerds, Inc.</p>
      </Container>
    </footer>
  );
};

export default Footer;
