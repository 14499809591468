// Function for how long ago a piece of content was posted
export const timeDifference = (publishedAt) => {
  const now = new Date();
  const contentDate = new Date(publishedAt);
  const diffMs = now - contentDate;
  const diffMinutes = Math.floor(diffMs / 60000);

  if (diffMinutes < 60) {
    return `${diffMinutes} minute${diffMinutes === 1 ? "" : "s"} ago`;
  } else if (diffMinutes < 1440) {
    // Less than 24 hours
    const diffHours = Math.floor(diffMinutes / 60);
    return `${diffHours} hour${diffHours === 1 ? "" : "s"} ago`;
  } else {
    const diffDays = Math.floor(diffMinutes / 1440); // 1440 minutes in a day
    return `${diffDays} day${diffDays === 1 ? "" : "s"} ago`;
  }
};
