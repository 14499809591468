import React from "react";
import { ListGroup } from "react-bootstrap";
import { getTeamAbbreviation } from "../utils/teamUtils"; // Import from teamUtils
import "./PlayerMovement.css";

const PlayerMovement = ({ transaction }) => {
  const {
    player_name,
    transaction_type,
    old_team_name,
    team_name,
    transaction_date,
  } = transaction;

  const formatTransaction = () => {
    switch (transaction_type) {
      case "waived":
        return `${player_name} was waived by ${old_team_name}`;
      case "trade":
        return `${player_name} was traded from ${old_team_name} to ${team_name}`;
      case "sign_free_agent":
        return `${player_name} was signed by ${team_name}`;
      case "placed_on_injured_reserve":
        return `${player_name} was placed on IR by ${old_team_name}`;
      case "activate_from_injured_reserve":
        return `${player_name} was activated from IR by ${team_name}`;
      case "suspended":
        return `${player_name} was suspended by the League`;
      case "suspension_lifted":
        return `${player_name}'s suspension was lifted by the League`;
      case "sign_from_practice_squad":
        return `${player_name} was signed from the ${team_name} practice squad`;
      case "sign_to_practice_squad":
        return `${player_name} was assigned to the ${team_name} practice squad`;
      default:
        return `${player_name} had a transaction`;
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return `${date.getMonth() + 1}/${date.getDate()}`;
  };

  // Use the imported getTeamAbbreviation function to get the abbreviation
  const logoSrc = `/images/${getTeamAbbreviation(
    team_name || old_team_name
  )}.png`;

  return (
    <div className="transaction-item">
      <div className="logo-container">
        <img
          src={logoSrc}
          alt={`${team_name || old_team_name}`}
          className="team-logo"
        />
      </div>
      <p className="transaction-text">
        {formatTransaction()} ({formatDate(transaction_date)})
      </p>
    </div>
  );
};

export default PlayerMovement;
