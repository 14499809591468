import React, { useEffect, useState } from "react";
import axios from "axios";
import { getTeamName } from "../utils/teamUtils";
import "./TrendingTopics.css";
import "bootstrap-icons/font/bootstrap-icons.css";

const TrendingTopics = () => {
  const [trendingItems, setTrendingItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTrendingData = async () => {
      try {
        const playersResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/trendingplayers?time_period_hours=2`
        );
        const teamsResponse = await axios.get(
          `${process.env.REACT_APP_API_URL}/trendingteams?time_period_hours=2`
        );

        const players = [
          { type: "header", label: "Trending Players" },
          ...playersResponse.data.data.slice(0, 8).map((item, index) => ({
            type: "player",
            name: item.player,
            rankChange: item.rankChange,
            position: index + 1, // Position for display
          })),
        ];

        const teams = [
          { type: "header", label: "Trending Teams" },
          ...teamsResponse.data.data.slice(0, 5).map((item, index) => ({
            type: "team",
            name: getTeamName(item.team),
            logo: `/images/${item.team}.png`,
            rankChange: item.rankChange,
            position: index + 1, // Position for display
          })),
        ];

        // Duplicate items only once for a smooth continuous scroll
        const combinedItems = [...players, ...teams];
        setTrendingItems([...combinedItems, ...combinedItems]);
      } catch (error) {
        console.error("Error fetching trending data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTrendingData();
  }, []);

  return (
    <div className="ticker-container">
      {loading ? (
        <div className="loading-message">Loading trending topics...</div>
      ) : (
        <div className="ticker-content">
          {trendingItems.map((item, index) => {
            if (item.type === "header") {
              return (
                <div key={`${item.label}-${index}`} className="ticker-header">
                  <img
                    src="/images/trending-topic-icon.png"
                    alt="Trending Icon"
                    className="trending-icon"
                  />
                  <span>{item.label}</span>
                </div>
              );
            }

            const rankChangeIndicator =
              item.rankChange > 0 ? (
                <span className="change-indicator text-success">
                  <i className="bi bi-caret-up-fill me-1"></i>
                  {item.rankChange}
                </span>
              ) : item.rankChange < 0 ? (
                <span className="change-indicator text-danger">
                  <i className="bi bi-caret-down-fill me-1"></i>
                  {Math.abs(item.rankChange)}
                </span>
              ) : (
                <span className="change-indicator text-muted">-</span>
              );

            return (
              <div key={index} className="ticker-item">
                <span className="item-position">{item.position}.</span>
                {item.type === "team" && (
                  <img
                    src={item.logo}
                    alt={item.name}
                    className="ticker-logo"
                  />
                )}
                <span>{item.name}</span>
                {rankChangeIndicator}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default TrendingTopics;
