import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Compare.css";

const Compare = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/compare`
        );
        setData(response.data || []);
      } catch (err) {
        console.error("Error fetching comparison data:", err);
        setError("Failed to fetch comparison data.");
      }
    };

    fetchData();
  }, []);

  const getCellStyle = (type, value) => {
    return type !== value ? { backgroundColor: "#ffe5e5" } : {};
  };

  return (
    <div className="container mt-5">
      <h1 className="mb-4">Model Comparison</h1>
      {error && <div className="alert alert-danger">{error}</div>}
      <div className="card p-4">
        <table className="table table-bordered">
          <thead>
            <tr>
              <th>Headline</th>
              <th>GPT-4-Turbo</th>
              <th>GPT-4o Mini</th>
              <th>GPT-4</th>
              <th>GPT-3.5</th>
            </tr>
          </thead>
          <tbody>
            {data.length > 0 ? (
              data.map((row, index) => (
                <tr key={index}>
                  <td>
                    <a href={row.url} target="_blank" rel="noopener noreferrer">
                      {row.headline}
                    </a>
                  </td>
                  <td style={getCellStyle(row.type, row.gpt4turbo_classification)}>
                    {row.gpt4turbo_classification || "N/A"}
                  </td>
                  <td style={getCellStyle(row.type, row.gpt4omini_classification)}>
                    {row.gpt4omini_classification || "N/A"}
                  </td>
                  <td style={getCellStyle(row.type, row.gpt4_classification)}>
                    {row.gpt4_classification || "N/A"}
                  </td>
                  <td style={getCellStyle(row.type, row.gpt35_classification)}>
                    {row.gpt35_classification || "N/A"}
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="5" className="text-center">
                  No comparison data available.
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Compare;
