// SearchBar.js
import React, { useState } from "react";
import { InputGroup, FormControl, ListGroup } from "react-bootstrap";
import "./SearchBar.css";

const SearchBar = ({
  searchText,
  setSearchText,
  filteredPlayers,
  filteredTeams,
}) => {
  const [showSuggestions, setShowSuggestions] = useState(false);

  // Filter players and teams based on the current search text
  const matchingPlayers = filteredPlayers.filter((player) =>
    player.toLowerCase().includes(searchText.toLowerCase())
  );

  const matchingTeams = filteredTeams.filter((team) =>
    team.full_name.toLowerCase().includes(searchText.toLowerCase())
  );

  const handleFocus = () => setShowSuggestions(true);
  const handleBlur = () => setShowSuggestions(false);

  return (
    <div className="search-bar-container">
      <InputGroup>
        <FormControl
          type="text"
          placeholder="Search by player or team"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className="search-input"
        />
      </InputGroup>

      {/* Display matching players and teams only if searchText is not empty and the input is focused */}
      {showSuggestions &&
        searchText &&
        (matchingPlayers.length > 0 || matchingTeams.length > 0) && (
          <ListGroup className="suggestions-list">
            {matchingPlayers.map((player, index) => (
              <ListGroup.Item key={index} action className="suggestion-item">
                {player}
              </ListGroup.Item>
            ))}
            {matchingTeams.map((team, index) => (
              <ListGroup.Item key={index} action className="suggestion-item">
                {team.full_name} ({team.abbreviation})
              </ListGroup.Item>
            ))}
          </ListGroup>
        )}
    </div>
  );
};

export default SearchBar;
